<template>
  <div class="text-left LoginNew" style="overflow-x: hidden">
    <div v-if="isLoading">
      <LoaderComp />
    </div>
  </div>
</template>

<script>
import LoaderComp from "../customize/component/LoaderComp.vue";
export default {
  components: {
    LoaderComp,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  mounted() {
    const currentWidth = window.innerWidth;
    const currentHeight = window.innerHeight;
    let obj = {
      type: "integration",
      code: this.$route.query.code,
    };

    if (currentWidth === 850 || currentHeight === 800) {
      window.opener.postMessage(obj, "*");
      window.close();
    } else {
      if (this.$route.params.integration == "hubspot") {
        this.$router.push(
          `/integrations/6?connectView=true&code=${this.$route.query.code}`
        );
      }
      if (this.$route.params.integration == "mail_chimp") {
        this.$router.push(
          `/integrations/4?connectView=true&code=${this.$route.query.code}`
        );
      }
    }
  },
};
</script>

<style scoped>
.LoginNew {
  height: 100vh;
}
.LoginNew .MainSection {
  padding: 2% 5%;
  background: #ffffff;
}
</style>
